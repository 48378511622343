import "bootstrap/dist/css/bootstrap.css";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import './assets/main.css'
import { loanAuth } from './firebase/app_config'


let app 

loanAuth.onAuthStateChanged(() => {
    //check if the app no content
    if (!app) {
        app = createApp(App).use(router).mount('#app')
    }
});

import "bootstrap/dist/js/bootstrap.js";


 
