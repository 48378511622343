<template>
    <div class="welcome container">
      <p>Welcome to Luckymwa Loan Website</p>
      <div v-if="LoginShow">
          <LoginForm  @divertToCommonRoom="goTocommonRoom"/>
          <p>No account with us? <span @click="LoginShow = false">SignUp</span>  instead</p>
          </div>
  
          <div v-else>
          <JoinForm @showLogin="LoginShow=true"  />
          <p>Have an account with us? <span @click="LoginShow = true">Login</span> instead</p>
         </div>
    </div>
  </template>
  
  <script>
  import { inject, provide, ref } from 'vue'
  //import the joinForms
  import JoinForm from '../components/JoinForms.vue'
  import LoginForm from '../components/LoginForm.vue'
  import { useRouter } from 'vue-router'
  
  export default {
      components:{JoinForm , LoginForm},
    setup() {

      // provide( 'arema', 'hello!')
          //refs
      const LoginShow = ref(true)

      const accountCreated = false;
          //register success message ref
      const registerSuccess = ref('')
      
          //get use oruter
          const divert = useRouter()
  
          const goTocommonRoom =()=>{
  
            //divert user to CommonRoom
            divert.push({ name: 'Common', })

        }
       
   
          
  
          return {LoginShow , goTocommonRoom , registerSuccess , }
      }
  
  }
  </script>
  
  <style>
  .welcome {
      text-align: center;
      padding: 20px 0;
    }
    /* form styles */
    .welcome form {
      width: 300px;
      margin: 20px auto;
      
    }
    .welcome label {
      display: block;
      margin: 20px 0 10px;
    }
    .welcome input {
      width: 100%;
      padding: 10px;
      border-radius: 20px;
      border: 1px solid #eee;
      outline: none;
      color: #999;
      margin: 10px auto;
    }
    .welcome span{
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
    .welcome button {
      margin: 20px auto;
    }
  </style>