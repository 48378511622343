<template>
   <div>
    <form @submit.prevent="handleSignUp" >
        <input type="text" required placeholder="display name" v-model="displayName">
        <input type="number" required placeholder="Mobile Number" v-model="phoneNumber">
        <input type="email" required placeholder="email" v-model="email">
        <input type="password" required placeholder="password" v-model="password">
        <div class="error">{{ error }}</div>
        <div class="success" v-if="userPostiveNotification">{{ userPostiveNotification }}</div>
        
        <button v-if="isMember">Sign Up</button>
    </form>
   </div>
</template>

<script >

import { provide, ref } from 'vue'
import exportSignUp from '@/composables/signupConfig'

export default {
    
 setup(props, context){
       
    //import expSignUp
    const {error, signup } = exportSignUp()
        //refs
    const isMember = ref(true)
    const displayName = ref('')
    const email = ref('')
    const password = ref('')
    const phoneNumber = ref('')
    const userPostiveNotification = ref('')

    const handleSignUp =async()=>{
        // console.log(displayName.value, email.value,password.value, phoneNumber.value)
        //call signup to create user in fb db
        await signup(email.value,password.value,phoneNumber.value,displayName.value)
        
        if(!error.value){
            email.value = '',password.value = '',phoneNumber.value='',displayName.value=''
            userPostiveNotification.value = 'Thank you , your  account has been successfully created, click Login below to login '

       
            isMember.value = false;
            
            

        }
        
        

    }


    return { displayName , email ,phoneNumber, password, handleSignUp, error , userPostiveNotification, isMember}
 }
}
</script>

<style>


</style>