<template>
    <div>
     <form @submit.prevent="handleLogin" >
         
         <input type="email" required placeholder="email" v-model="email">
         <input type="password" required placeholder="password" v-model="password">
         <div class="error">{{ error }}</div>
         <div class="success" v-if="successf">{{ successf }}</div>
        
       
         <button> Log in</button>
     </form>
    </div>
 </template>
 
 <script>
 import { inject, ref } from 'vue'
import exportLogin from '../composables/loginConfig'



export default {
  
  setup(props, context){
     //refs
    
   
     const email = ref('')
     const password = ref('')
     const successf = ref('')
     const registerSuccess = ref(false)

     const {error , login} = exportLogin()
 
     const handleLogin =async()=>{
        await login( email.value, password.value)
         
         //check erorr
         if(!error.value){
            console.log('user login sussesfuly')
            successf.value = 'user login sussesfuly'
            //emit or divert user when they logged in 
            context.emit('divertToCommonRoom')
            
            
         }
     }
   //   mesagesAccCreated.value =props.accCreated
 
   
     return {   email , password, handleLogin ,error , successf}
  }
 }
 </script>
 
 <style>
 
 
 </style>