import { ref } from "vue"
import { loanAuth } from "../firebase/app_config"

//create user ref
const user = ref(loanAuth.currentUser)

//get pmAuthStateChange method
loanAuth.onAuthStateChanged(_user => {

    console.log('User state change : Current User is :',_user)
    user.value = _user
})

// expgetUser
const expgetUser = () => {
    return { user}
}

export default expgetUser