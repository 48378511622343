<template>
   <div class="container">
    <Navbar />
    <ChatWindow />
    <ChatForm />
   </div>
  </template>

<script>
import  ChatForm  from '../components/ChatForm.vue'
import  ChatWindow  from '../composables/ChatWidow.vue'
import { watch } from 'vue';
import Navbar from '../components/Navbar.vue'
import { useRouter } from 'vue-router';

import expgetUser from '@/composables/getUserConfig';
export default {
  components:{Navbar , ChatForm , ChatWindow},
setup() {
  //get user
  const { user } = expgetUser()
  // init
  const divert =useRouter()
  watch(user, () => {
    if (!user.value) {
      divert.push({name:'Login'})
    }
  })
}
}
</script>

<style>


</style>