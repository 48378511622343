import { createRouter, createWebHistory } from 'vue-router'

import Common from '../views/Commonroom.vue'
import Chat from '../views/Chatroom.vue'
import Login from '../views/Login.vue'
import { loanAuth } from "../firebase/app_config"

//suth guard method
const protectPageAuthNeed = (to, from, next) => {
  //get logged in user
  let user = loanAuth.currentUser
  console.log('user logged in is:', user)
  //check user logged in means

  if (!user) {
     next({name:'Login'}) //divert back to login page
  } else {
    next()//allow user to go to Chatroom
  }


 
  
}


const routes = [
  {
    path:'/',
    name:'Login',
    component: Login
  },

  {
    path:'/chatroom',
    name:'Chatroom',
    component: Chat,
    beforeEnter:protectPageAuthNeed
  },
  {
    path:'/common',
    name:'Common',
    component:Common,
  },
 
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
