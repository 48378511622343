<template>
  <form  @submit.prevent="sendMessage" >
    <textarea
      placeholder="Taiaoka ma korea ikai am maroro ao kotea Enter n kanakoa am maroro "
       v-model="message"
        >

 
    </textarea>
    
    <div v-if="error" class="error">{{ error }} blah</div>
    <button>Send message</button>
  </form>
</template>

<script>
import { ref } from 'vue'
import expgetUser from '@/composables/getUserConfig'
import { timestamp } from '@/firebase/app_config'
import useCollection from '../composables/useCollectionConfig'
export default {
    setup() {

        const { user } = expgetUser()//import user from fb db
        const { addDoc , error } = useCollection('message')

        const message = ref('')//ref
      
        const sendMessage = async() => {
            const chat = {

               name: user.value.displayName,//get user type message

                message: message.value,//get  user message

                createdAt: timestamp()
            }
            //call addDoc
            await addDoc(chat)
            //check error
            if (!error.value) {
                message.value =''//clear message
            }

            
        }
        return { message , sendMessage , error}

    }

}
</script>

<style scoped>
  form {
    margin: 10px;
  }
  textarea {
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
    padding: 10px;
    box-sizing: border-box;
    border: 0;
    border-radius: 20px;
    font-family: inherit;
    outline: none;
  }
  .error {
    text-align: center;
    color: #ff2a58;
    font-size: 12px;
    padding: 10px 0;
  }

</style>