import { ref } from 'vue'
import { loanfirestore } from '../firebase/app_config'

//create useCollection = (collection)

const useCollection = (collection) => {
    const error = ref(null)

    //create addDoc 
    const addDoc = async (doc) => {
        error.value = null //reset any error

        //try and catch
        try {
            await loanfirestore.collection(collection).add(doc)

        } catch (err) {
            console.log(err.message)
            error.value = 'could not send message'
        }
    }
    return {error , addDoc}
}

export default useCollection