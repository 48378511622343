

import firebase from 'firebase/app'
import 'firebase/firestore'

//init firebase auth
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDrnvuKmBXNfn4hpHNntwvWPkgTh3cNNIo",
    authDomain: "rick-john-loan-app.firebaseapp.com",
    projectId: "rick-john-loan-app",
    storageBucket: "rick-john-loan-app.appspot.com",
    messagingSenderId: "346960736418",
    appId: "1:346960736418:web:636bd42f6b2a6471cfa7fb"
};
  


  //init firebase
  firebase.initializeApp(firebaseConfig)

//get firebase Auth
const loanAuth = firebase.auth()
  //init firestore
  const loanfirestore = firebase.firestore()

  //create timestamp 
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  


  //export them
  export {loanAuth,  loanfirestore , timestamp }
