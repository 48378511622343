import { ref } from "vue"
import { loanAuth } from "../firebase/app_config"

const error = ref(null)



const signup = async (email,password,phoneNumber,displayName) => {
   //reset error 
   error.value = null

   //try and catch err
   try{
  const res =   await loanAuth.createUserWithEmailAndPassword(email,password)
  //check error for res
  if(!res){
    throw new Error('Could not complete Signup ')
  }
  //set display updateProfile
  await res.user.updateProfile({displayName})
  error.value = null

  return res

   }catch(err){
     console.log(err.message)
     error.value = err.message
   }
}

//expSignUp

const exportSignUp =()=>{
   return {error , signup}
}

export default exportSignUp