<template>
  <div class="container">
    <h2>Loan Application Form</h2>
    <form @submit.prevent="handleLoan" >


<div class="loanIncome mt-5" >
    <div class="input-group mb-3 w-75 me-3" >
  <span class="input-group-text">$</span>
  <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder="Amount of Loan">
  <span class="input-group-text">.00</span>
</div>
<div class="input-group mb-3 w-75">
  <span class="input-group-text">$</span>
  <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder="Wage in 2 Weeks">
  <span class="input-group-text">.00</span>
</div>
</div>
<div>
<label for="membership-type">Payment Type:</label>     
<select class="form-select" aria-label="Default select example">
  <option selected>Payment Type</option>
  <option value="1">Bank Online</option>
  <option value="2">Manual</option>
  
</select>
</div>

<div>
  <label for="membership-duration">Late Fees 10% after 2 weeks due date:</label>

<select class="form-select" aria-label="Default select example">
  <option selected>Loan Duration</option>
  <option value="monthly">2 weeks</option>
                <option value="yearly">2 weeks</option>
  
</select>
</div>
 
       
 
        <div class="form-group">
            <label for="emergency-contact">Emergency Contact:</label>
            <input type="text" id="emergency-contact" name="emergency-contact">
        </div>

        <div class="card border-primary mb-3" style="max-width: 40rem;">
  <div class="card-header">Loan Tearms</div>
  <div class="card-body text-primary">
    <h5 class="card-title">Kaotako raoi n wareka te Terms </h5>
    <p class="card-text">I karaiai bwa te mwaiti n money are nna tangoia ena iai rakana ae 10% ngkana i kabwara n te maan ae 2 weeks ngkana eaki bwara 
            inanon 2 WEEKS (14 days) ao iai manga 10% ae ena manga iai riki te mwaiti ae 10% ae ena ena manga ikotaki nakon au mwaiti  nn ariai n kabaka n 2 weeks ma rakana are 10%</p>
            <p id="note">NOTE: Ti aki kariai ae kona ti kabwara 10% ngkana ko kabara am Tarau. N aki irakin nanon anne ao ti karautaeka nakoim bwa ko aki kona naba manga tango mwane riki imwina. Ko rabwa.</p>
  </div>
</div>


    
            
   
    
        <div class="form-check form-switch  mt-3 input-group-text " >
            <input @click="Iagree " class="form-check-input ms-5 " type="checkbox" role="switch" id="flexSwitchCheckDefault">
  <label class="form-check-label ms-5" for="flexSwitchCheckDefault">Please tick box to agree to the above Loan termst</label>
  
</div>



      
        
        <div class="form-group">
            
            <P v-if="acceptLoan" class="success">{{ acceptLoan }}</P>
            <div  v-if="agreeTerms"> <button  class="btn-btn-danger mt-3">Sumbit</button></div>
           
           
            

        </div>
    </form>
</div>
</template>

<script>
import { ref } from 'vue'
export default {

    setup() {
        //ref
        const acceptLoan = ref("")
        const agreeTerms = ref(false)


        const handleLoan = () => {
            acceptLoan.value="Ko rabwa , e areke inanon baira am Loan aio ti na rinanoia ao tia manga okiriko n kanongoko . Ko rarabwa tekeraoi "
        }
        const Iagree = () => {
           
           
                agreeTerms.value = true
 }

        return { handleLoan , acceptLoan , Iagree ,agreeTerms }
    }

}
</script>

<style scoped>

    body {
        font-family: Arial, sans-serif;
        background-color: #ecc40f;
        margin: 0;
        padding: 0;
    }
    .container {
        max-width: 600px;
        margin: 20px auto;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    #terms{
        background: lightgray;
    }
    #note{
        margin-top: 20px;
        background:#ecc40f;
        padding:5px;
    }
    #terms{
        font-size: 10px;
    }
    .agreeWords{
        background: gray;
        color: white;
        font-size: 15px;
        
    }
    h1 {
        
       
        margin-bottom: 20px;
    }
    .loanIncome{
        justify-content: space-between;
        
        display: flex;
    }
    .details{
        justify-content: space-around;
        display: flex;
    }
    .form-group {
        margin-bottom: 20px;
    }
    .form-group label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .form-group input[type="text"], .form-group input[type="email"] {
        width: calc(100% - 12px);
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .form-group input[type="submit"] {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .form-group input[type="submit"]:hover {
        background-color: #0056b3;
    }

</style>