<template>
 <navbar />
  <LoanForm />
</template>

<script>
import Navbar from '../components/Navbar.vue'
import LoanForm from '../components/LoanForm.vue'
export default {
  components:{ LoanForm, Navbar}

}
</script>

<style>

</style>