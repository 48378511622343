<template>


  <nav class="navbar  navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">  <button type="button" class="btn btn-primary position-relative">
         <div ></div> {{ user.displayName }}
  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    Welcome
    <span class="visually-hidden">unread messages</span>
  </span>
</button></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Pricing</a>
        </li>
      </ul>
      <span class="navbar-text" v-if="user">

      
        
       
        <button class="d-inline-block " @click="logoutM">Logout</button>
        
   
      </span>
    </div>
  </div>
</nav>
</template>

<script>
//import logut config 
import expLogout from '../composables/logoutConfig'
import expgetUser from '../composables/getUserConfig'
import { useRouter } from 'vue-router'
export default {
  setup() {
    // init logout
    const { logout, error } = expLogout()
    //init getUser
    const { user } = expgetUser()

    //init useRouter
    const divert = useRouter()

    //logut method
    const logoutM = async () => {
      await logout()
      if (!error.value) {
        console.log('user logged out')
        divert.push({name:'Login'})
        
      }
    }
    return {logoutM , user}
  }
}
</script>

<style>
  nav {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav p {
    margin: 2px auto;
    font-size: 16px;
    color: #444;
  }
  nav p.email {
    font-size: 14px;
    color: #999;
  }

</style>