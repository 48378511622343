import { ref } from "vue"
import { loanAuth } from "../firebase/app_config"

const error = ref(null)



const login = async (email,password) => {
   //reset error 
   error.value = null

   //try and catch err
   try{
  const res =   await loanAuth.signInWithEmailAndPassword(email,password)
  //reset errpr
  error.value = null
  
 console.log(res)

 return res

  

   }catch(err){
     console.log(err.value)
     error.value = "Login credential not right"
   }
}

//expSignUp

const exportLogin =()=>{
   return {error , login}
}

export default exportLogin