import { ref } from 'vue'

import { loanfirestore } from '../firebase/app_config'


//getCollection method

const getCollection = (collection) => {
    const documents = ref(null)
    const error = ref(null)


      // register the firestore collection reference
    let collectionRef = loanfirestore.collection(collection).orderBy('createdAt')


    collectionRef.onSnapshot(snap => {
        let results = []
        snap.docs.forEach(doc => {
            // must wait for the server to create the timestamp & send it back
            // we don't want to edit data until it has done this
            doc.data().createdAt && results.push({
                ...doc.data(), id: doc.id //check createdAt if false it will get timestamp from the db then push all items from firebas db to result  
            });
            //update document from the results
            documents.value = results
            error.value = null
        }, err => {
            console.log(err.message)
            documents.value = null
            error.value = 'could not fetch data'
        })
        
    })

    return { documents, error  }
}

export default getCollection