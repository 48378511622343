import {ref} from "vue"

import { loanAuth } from '../firebase/app_config'


const error = ref(null)


//logut async method

const logout = async () => {
    //reset error
    error.value = null
    //try method
    try {
        await loanAuth.signOut()
    } catch (err) {
        console.log(err.message)
        //asign error to error ref
        error.value = err.message
    }
}

//export logout method
const expLogout = () => {
  return { logout,error}
}

//export logut
export default expLogout

